<template>
  <div class="signIn">
    <div class="title">Sign in now</div>
    <div class="font">
      <p>New user? Are you interested in becoming one of our customers ?</p>
      <p>
        Please fill out the online registration form on the registration page to
        open an username.
      </p>
    </div>
    <div class="form col-sm-12 col-xs-12 col-lg-10 col-xl-10">
      <div class="single">
        <section>Username / Email</section>
        <input
          type="text"
          placeholder="Username / Email "
          v-model="ruleForm.account"
        />
      </div>
      <div class="single">
        <section>Password</section>
        <input
          type="password"
          placeholder="Password"
          v-model="ruleForm.password"
        />
      </div>

      <div class="option">
        <div class="remenber">
          <input type="checkbox" v-model="ruleForm.checked" />
          <span>Remenber Me</span>
        </div>
        <div class="forgetten" @click="path">Forgot</div>
      </div>
      <div class="flex">
        <div class="button" @click="submitForm">Sign In Now</div>
        <div class="button" @click="goRegister">Register</div>
      </div>
    </div>
  </div>
</template>
<script >
import Cookie from "cookie_js";
import CryptoJS from "crypto-js";
import { login } from "network/login";
export default {
  data() {
    name: "signIn";
    return {
      ruleForm: {
        checked: false,
        account: "",
        password: "",
      },
      m: "",
    };
  },
  created() {
    let cookieInfo = Cookie.get(["key1", "key2", "key3"]); //获取上一次有没有保存到cookie
    this.m = cookieInfo.key3;
    //保存的话 从cookie里面取出 填文本框里面去
    if (cookieInfo.key1 == "true") {
      this.ruleForm.checked = Boolean(cookieInfo.key1);
      this.ruleForm.account = cookieInfo.key2;
      var j = CryptoJS.AES.decrypt(this.m, "rzb");
      var ps = j.toString(CryptoJS.enc.Utf8);
      this.ruleForm.password = ps;
    } else {
      return false;
    }
  },

  methods: {
    submitForm() {
      login(this.ruleForm.account, this.ruleForm.password).then((res) => {
        if (res.data.code == 1) {
          //this.$store.commit("loginStausT");
          let data = res.data.data.userinfo;
          //delite
          // this.$store.commit("changeToken", data);
          // Cookie.set("token", data.token, data.expires_in);

          this.$store.dispatch("setAccount", data);
          this.$router.push("/member");
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });

      //如果上一次本就是保存状态  点击提交 有新的提交新的 无新的提交cookie里面的
      if (this.ruleForm.checked) {
        this.m = CryptoJS.AES.encrypt(this.ruleForm.password, "rzb").toString();
        Cookie.set(
          {
            key1: this.ruleForm.checked,
            key2: this.ruleForm.account,
            key3: this.m,
          },
          {
            expires: 7,
          }
        );
      } else {
        Cookie.remove(["key1", "key2", "key3"]); //取消记住密码 清楚cookie
      }
    },
    path() {
      this.$router.push("/forgetten");
    },
    goRegister() {
      this.$router.push("/register1");
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.signIn {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.title {
  color: #333333;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
}
.font {
  text-align: center;
  color: #737373;
  font-size: 0.4rem;
  margin-top: 1rem;
}
.font p:last-child {
  margin-top: 0.25rem;
}
section {
  color: #666666;
  font-size: 0.4rem;
  font-weight: 600;
}
.single {
  margin-top: 0.75rem;
}

.form {
  margin: 0.25rem auto;
}
.single input {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
}
.remenber input {
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 0.5rem;
}
.option {
  color: #666666;
  font-size: 0.4rem;
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-between;
}
.remenber {
  display: flex;
  align-items: center;
}
.button {
  margin: 1rem auto;
  font-size: 0.4rem;
  color: #fff;
  padding: 0.3rem 1rem;
  background: #4d4d4d;
  border-radius: 0.2rem;
  text-align: center;
  cursor: pointer;
}
.button:hover {
  background: #ff7f00;
  color: #fff;
}
.forgetten {
  cursor: pointer;
}
.flex {
  display: flex;
}
</style>